jQuery(document).ready( function() {

// keyboard controls for mobile nav

  var cntrlNavLabel = jQuery('#cntrlNavLabel');
  var cntrlNav = jQuery('#cntrlNav');

  cntrlNavLabel.keyup(function(event) {
    event.preventDefault();
    if(event.keyCode === 13) { // enter key pressed
      cntrlNav.prop("checked", !cntrlNav.prop("checked"));
    }
    event.stopPropagation();
  });

  jQuery(document).keyup(function(event) {
     if (event.keyCode == 27 && cntrlNav.prop("checked")) { // escape key maps to keycode `27`
       cntrlNav.prop("checked", !cntrlNav.prop("checked"));
    }
    event.stopPropagation();
  });

}); // ends document.ready
